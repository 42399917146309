const PRIMARY_COLOR = "#0D00AA";
const DEEP_BLUE_COLOR = "#112761";
const GREY_FONT_COLOR = "#6B6B6B";
const WHITE_FONT_COLOR = "#FFFFFF";

export {
    PRIMARY_COLOR,
    DEEP_BLUE_COLOR,
    GREY_FONT_COLOR,
    WHITE_FONT_COLOR,
}